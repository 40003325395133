import React, { createContext, useEffect, useState } from 'react'

import { useStaticQuery, graphql } from 'gatsby'

import {
    addBundleToStrapiCart,
    addProductToStrapiCart,
    createStrapiCartID,
    decreaseBundleToStrapiCart,
    decreaseProductToStrapiCart,
    deleteStrapiCart,
    getBundleFromStrapi,
    getProductFromStrapi,
    getStrapiCart,
    increaseBundleToStrapiCart,
    increaseProductToStrapiCart,
    removeBundleToStrapiCart,
    removeProductToStrapiCart,
} from '../utils/strapi'
import useWindowWidth from '../hooks/useWindowWidth'
import { addCustomerToNewsletter } from '../utils/newsletterUtils'
import { TrackAddProductToCartEvent } from '../utils/google-analytics'

export const AppContext = createContext()

export const AppProvider = ({ children }) => {
    const data = useStaticQuery(graphql`
        query {
            strapiWerte {
                Versandkosten
                Versandkostenfrei_ab
                Gratis_Produkt_ab
            }
            strapiGratisProdukt {
                Gratis_Produkt {
                    produkt {
                        strapi_id
                        Name
                        slug
                        kategorie {
                            Name
                        }
                        marke {
                            Name
                        }
                        Variante {
                            groesse {
                                Name
                                strapi_id
                            }
                            Bildergalerie {
                                url
                            }
                            Vorratsanzahl
                        }
                        Vorschaubild {
                            url
                        }
                        edition {
                            Name
                        }
                    }
                    groesse {
                        Name
                        strapi_id
                    }
                    Anzahl
                }
            }
            strapiUpSalesProdukt {
                Produkt {
                    produkt {
                        strapi_id
                        Name
                        slug
                        kategorie {
                            Name
                        }
                        marke {
                            Name
                        }
                        Variante {
                            groesse {
                                Name
                                strapi_id
                            }
                            Bildergalerie {
                                url
                            }
                            Vorratsanzahl
                            Preis
                            Preis_Angebot
                        }
                        Vorschaubild {
                            url
                        }
                        edition {
                            Name
                        }
                    }
                    groesse {
                        Name
                        strapi_id
                    }
                    Anzahl
                }
            }
        }
    `)

    const [versandkostenValue] = useState(() => {
        return data.strapiWerte?.Versandkosten || 0
    })

    const [versandkostenfreiAb] = useState(() => {
        return data.strapiWerte?.Versandkostenfrei_ab || 0
    })

    const [gratisProduktAb] = useState(() => {
        return data.strapiWerte?.Gratis_Produkt_ab || 0
    })

    const [gratisProdukte] = useState(() => {
        return data.strapiGratisProdukt?.Gratis_Produkt || []
    })

    const [upSalesProdukte] = useState(() => {
        return data.strapiUpSalesProdukt?.Produkt || []
    })

    const isBrowser = typeof window !== `undefined`
    const windowWidth = useWindowWidth()

    // Browser Language
    const [language] = useState(() => {
        // if (isBrowser) {
        //     if (window.localStorage.browserLanguage) {
        //         return JSON.parse(window.localStorage.browserLanguage)
        //     } else {
        //         if (window.navigator.language === 'de-DE') {
        //             return 'de'
        //         }
        //         return 'en'
        //     }
        // } else {
        //     return 'en'
        // }

        return 'de'
    })

    // PopUp
    const [popUpIsOpen, setPopUpIsOpen] = useState(false)
    const [checkoutPopUpIsOpen, setCheckoutPopUpIsOpen] = useState(false)

    // Sidebar
    const [showNavbar, setShowNavbar] = useState(false)
    const [isSidebarOpen, setIsSidebarOpen] = useState(false)
    const handleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    const closeSidebar = () => {
        setIsSidebarOpen(false)
    }

    useEffect(() => {
        if (windowWidth < 1024) {
            if (isSidebarOpen) {
                setShowNavbar(false)
            }
        }
    }, [windowWidth, isSidebarOpen])

    // Cart
    const [cart, setCart] = useState([])

    const [cartID, setCartID] = useState(() => {
        if (isBrowser) {
            return JSON.parse(window.localStorage.CartID || null)
        } else {
            return null
        }
    })

    const resetCart = async () => {
        //  Delete Strapi Cart
        if (cartID) {
            await deleteStrapiCart(cartID)
            setCartID(null)
            setCart([])
        }

        return true
    }

    useEffect(() => {
        setCartIsLoading(true)
        const getCart = async () => {
            if (cartID) {
                const strapiCart = await getStrapiCart(cartID)
                if (strapiCart) {
                    // ***** Get all products from cart
                    const allProducts = strapiCart?.attributes?.Cart_Produkte
                    let allProductsArray = []

                    // create an array with objects that include count, product_strapi_id and groesse_id
                    allProducts.forEach(currentStrapiProduct => {
                        let strapiProduct = {}
                        strapiProduct.product_type = 'product'
                        strapiProduct.product_strapi_id =
                            currentStrapiProduct?.produkt?.data?.id
                        strapiProduct.groesse_id =
                            currentStrapiProduct?.groesse?.data?.id
                        strapiProduct.product_count =
                            currentStrapiProduct?.Anzahl

                        allProductsArray.push(strapiProduct)
                    })

                    // get all products from strapi
                    const allProductsFromStrapi = await Promise.all(
                        allProductsArray.map(async currentProduct => {
                            const product = await getProductFromStrapi(
                                currentProduct.product_strapi_id
                            )

                            const productVariant =
                                product.attributes?.Variante?.find(
                                    item =>
                                        item.groesse.data.id ===
                                        currentProduct?.groesse_id
                                )

                            let productVariantImage =
                                product.attributes?.Vorschaubild?.data
                                    ?.attributes?.url

                            if (productVariant) {
                                if (
                                    productVariant?.Bildergalerie?.data
                                        ?.length > 0
                                ) {
                                    productVariantImage =
                                        productVariant?.Bildergalerie?.data[0]
                                            ?.attributes?.url
                                }
                            }

                            const formattedProduct = {
                                product_type: 'product',
                                product_strapi_id: product.id,
                                product_name: product.attributes?.Name,
                                product_slug: product.attributes?.slug,
                                product_edition:
                                    product.attributes?.edition?.data
                                        ?.attributes?.Name,
                                product_variant:
                                    productVariant?.groesse?.data.attributes
                                        ?.Name,
                                product_variant_strapi_id:
                                    productVariant?.groesse?.data?.id,
                                product_variant_price:
                                    productVariant?.Preis_Angebot ||
                                    productVariant?.Preis,
                                product_variant_normal_price:
                                    productVariant?.Preis,
                                product_variant_discount: 0,
                                product_count: currentProduct.product_count,
                                product_image: productVariantImage,
                            }

                            return formattedProduct
                        })
                    )

                    // ***** Get all bundles from cart
                    const allBundles = strapiCart?.attributes?.Cart_Bundles
                    let allBundlesArray = []

                    // create an array with objects that include count, product_strapi_id and groesse_id
                    allBundles.forEach(currentStrapiBundle => {
                        let strapiBundle = {}
                        strapiBundle.product_type = 'bundle'

                        strapiBundle.product_strapi_id =
                            currentStrapiBundle?.bundle?.data?.id

                        strapiBundle.product_variant_one =
                            currentStrapiBundle?.Produkt_1

                        strapiBundle.product_variant_two =
                            currentStrapiBundle?.Produkt_2

                        strapiBundle.product_count = currentStrapiBundle?.Anzahl

                        allBundlesArray.push(strapiBundle)
                    })

                    // get all bundles from strapi
                    const allBundlesFromStrapi = await Promise.all(
                        allBundlesArray.map(async currentBundle => {
                            const strapi_bundle = await getBundleFromStrapi(
                                currentBundle.product_strapi_id
                            )

                            const formattedBundle = {
                                product_type: 'bundle',
                                product_strapi_id: strapi_bundle.id,
                                product_name: strapi_bundle?.attributes?.Name,
                                product_edition:
                                    strapi_bundle?.attributes?.edition?.data
                                        ?.attributes?.Name,
                                product_count: currentBundle.product_count,
                                product_one_strapi_id:
                                    strapi_bundle?.attributes?.Produkt[0]
                                        ?.produkt?.data?.id,
                                product_two_strapi_id:
                                    strapi_bundle?.attributes?.Produkt[1]
                                        ?.produkt?.data?.id,
                                product_one_name:
                                    strapi_bundle?.attributes?.Produkt[0]
                                        ?.produkt?.data?.attributes?.Name,
                                product_two_name:
                                    strapi_bundle?.attributes?.Produkt[1]
                                        ?.produkt?.data?.attributes?.Name,
                                product_one_edition:
                                    strapi_bundle?.attributes?.Produkt[0]
                                        ?.produkt?.data?.attributes?.edition
                                        ?.data?.attributes?.Name,
                                product_two_edition:
                                    strapi_bundle?.attributes?.Produkt[1]
                                        ?.produkt?.data?.attributes?.edition
                                        ?.data?.attributes?.Name,
                                product_one_anzahl:
                                    strapi_bundle?.attributes?.Produkt[0]
                                        ?.Anzahl,
                                product_two_anzahl:
                                    strapi_bundle?.attributes?.Produkt[1]
                                        ?.Anzahl,
                                product_variant_one:
                                    currentBundle.product_variant_one,
                                product_variant_two:
                                    currentBundle.product_variant_two,
                                product_variant_price:
                                    strapi_bundle?.attributes?.Preis,
                                product_one_image:
                                    strapi_bundle?.attributes?.Produkt[0]
                                        ?.produkt?.data?.attributes
                                        ?.Vorschaubild?.data?.attributes?.url,
                                product_two_image:
                                    strapi_bundle?.attributes?.Produkt[1]
                                        ?.produkt?.data?.attributes
                                        ?.Vorschaubild?.data?.attributes?.url,
                            }
                            return formattedBundle
                        })
                    )

                    // allItemsArray is an array with all products and bundles
                    const allItemsArray = [
                        ...allProductsFromStrapi,
                        ...allBundlesFromStrapi,
                    ]

                    setCart(allItemsArray)
                }
            }
            setCartIsLoading(false)
        }
        getCart()

        return () => {
            setCartIsLoading(false)
        }
    }, [cartID])

    const [cartIsLoading, setCartIsLoading] = useState(false)

    const [cartProductCount, setCartProductCount] = useState(0)
    const [cartTotalPrice, setCartTotalPrice] = useState(0)

    const [deliveryForm, setDeliveryForm] = useState({
        email: '',
        telephone: '',
        deliveryCountry: 'DE',
        deliveryFirstName: '',
        deliveryLastName: '',
        deliveryCompany: '',
        deliveryStreet: '',
        deliveryAdditionalInformation: '',
        deliveryZipCode: '',
        deliveryCity: '',
        otherBillingAddress: false,
        billingCountry: 'DE',
        billingFirstName: '',
        billingLastName: '',
        billingCompany: '',
        billingStreet: '',
        billingAdditionalInformation: '',
        billingZipCode: '',
        billingCity: '',
        acceptTerms: false,
        acceptDHLCheck: false,
    })

    const [isCaptureCheckout, setIsCaptureCheckout] = useState(false)
    const [captureCheckoutOrderPayerId, setCaptureCheckoutOrderPayerId] =
        useState(null)
    const [captureCheckoutOrderOrderId, setCaptureCheckoutOrderOrderId] =
        useState(null)
    const [captureCheckoutActions, setCaptureCheckoutActions] = useState(null)

    const [deliveryMessage, setDeliveryMessage] = useState('')

    const [deliveryOption, setDeliveryOption] = useState(null)

    const [couponDiscount, setCouponDiscount] = useState(null)
    const [couponDiscountID, setCouponDiscountID] = useState(null)
    const [couponDiscountVariant, setCouponDiscountVariant] = useState(null)
    const [couponName, setCouponName] = useState(null)
    const [deliveryDiscount, setDeliveryDiscount] = useState(null)
    const [discount, setDiscount] = useState(null)

    const [couponDiscountValue, setCouponDiscountValue] = useState(0)
    const [deliveryDiscountValue, setDeliveryDiscountValue] = useState(0)
    const [discountValue, setDiscountValue] = useState(0)
    const [discountProducts, setDiscountProducts] = useState([])
    const [ohneAktionsprodukte, setOhneAktionsprodukte] = useState(false)

    const [paymentOption, setPaymentOption] = useState('PayPal')

    useEffect(() => {
        if (
            deliveryOption?.key === 'Werksverkauf' ||
            paymentOption === 'Banküberweisung'
        ) {
            setDeliveryDiscount(0.05)
        } else {
            setDeliveryDiscount(null)
        }
    }, [deliveryOption, paymentOption])

    useEffect(() => {
        if (deliveryOption?.key === 'Werksverkauf') {
            setPaymentOption('PayPal')
        }
    }, [deliveryOption])

    useEffect(() => {
        // reset item.product_variant_discount on every item in cart to 0
        const newCart = [...cart]
        newCart.forEach(item => {
            item.product_variant_discount = 0
        })
        setCart(newCart)
        if (couponDiscount) {
            if (couponDiscountVariant === 'Gutscheincode') {
                if (cartTotalPrice < couponDiscount) {
                    setCouponDiscountValue(
                        cartTotalPrice - deliveryDiscountValue
                    )
                } else {
                    setCouponDiscountValue(couponDiscount)
                }
            } else {
                if (discountProducts?.length > 0) {
                    const formattedDiscountProducts = discountProducts.map(
                        item => ({
                            produkt: item.produkt?.data?.id,
                            groesse: item.groesse?.data?.attributes?.Name || '',
                        })
                    )
                    // map through cart and check if there is a product with the same strapi_id and variant as the formattedDiscountProducts
                    const discountProductsInCart = cart.filter(item =>
                        formattedDiscountProducts.find(
                            discountProduct =>
                                discountProduct.produkt ===
                                    item.product_strapi_id &&
                                discountProduct.groesse === item.product_variant
                        )
                    )

                    discountProductsInCart.forEach(item => {
                        if (
                            ohneAktionsprodukte &&
                            item.product_variant_price <
                                item.product_variant_normal_price
                        ) {
                            item.product_variant_discount = 0
                        } else {
                            item.product_variant_discount =
                                couponDiscount * item.product_variant_price
                        }
                    })

                    // update local cart
                    const newCart = [...cart]

                    discountProductsInCart.forEach(discountItem => {
                        const productInCart = newCart.find(
                            cartItem =>
                                cartItem.product_strapi_id ===
                                    discountItem.product_strapi_id &&
                                cartItem.product_variant ===
                                    discountItem.product_variant
                        )
                        if (productInCart) {
                            productInCart.product_variant_discount =
                                discountItem.product_variant_discount
                        }
                    })

                    setCart(newCart)
                } else {
                    if (ohneAktionsprodukte) {
                        // map thorugh cart and if "product_variant_normal_price" is the same as "product_variant_price" then apply the discount and update product_variant_discount
                        const newCart = [...cart]

                        newCart.forEach(item => {
                            if (
                                item.product_variant_price ===
                                item.product_variant_normal_price
                            ) {
                                item.product_variant_discount =
                                    couponDiscount * item.product_variant_price
                            }
                        })

                        setCart(newCart)
                    } else {
                        const newCart = [...cart]

                        newCart.forEach(item => {
                            item.product_variant_discount =
                                couponDiscount * item.product_variant_price
                        })

                        setCart(newCart)
                    }
                }
            }
        } else {
            setCouponDiscountValue(0)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        ohneAktionsprodukte,
        couponDiscount,
        deliveryDiscountValue,
        cartTotalPrice,
        couponDiscountVariant,
        discountProducts,
    ])

    useEffect(() => {
        if (deliveryDiscount) {
            setDeliveryDiscountValue(
                Number((cartTotalPrice * deliveryDiscount).toFixed(2))
            )
        } else {
            setDeliveryDiscountValue(0)
        }
    }, [deliveryDiscount, cartTotalPrice])

    useEffect(() => {
        if (couponDiscount || deliveryDiscount) {
            setDiscount((couponDiscount + deliveryDiscount).toFixed(2))
        } else {
            setDiscount(null)
        }
    }, [couponDiscount, deliveryDiscount])

    useEffect(() => {
        if (discount) {
            setDiscountValue(
                Number((couponDiscountValue + deliveryDiscountValue).toFixed(2))
            )
        } else {
            setDiscountValue(0)
        }
    }, [discount, couponDiscountValue, deliveryDiscountValue, cartTotalPrice])

    useEffect(() => {
        // cartProductCount
        let count = 0
        cart.forEach(item => {
            count += item.product_count
        })
        setCartProductCount(count)

        // cartTotalPrice
        let price = 0
        let discount = 0
        cart.forEach(item => {
            price += item.product_variant_price * item.product_count
            discount += item.product_variant_discount * item.product_count
        })

        if (couponDiscountVariant !== 'Gutscheincode') {
            setCouponDiscountValue(discount)
        }
        setCartTotalPrice(price)
    }, [cart, couponDiscountVariant])

    // FUNCTIONS
    const checkForCartID = async (isFreshCart = false) => {
        if (!cartID || isFreshCart) {
            const cartUid = await createStrapiCartID()

            if (cartUid) {
                setCartID(cartUid)
                if (isBrowser) {
                    window.localStorage.CartID = JSON.stringify(cartUid)
                }
                return cartUid
            }
        } else {
            return cartID
        }
    }

    const addProductToCart = async (
        product,
        variant,
        count,
        isFreshCart = false
    ) => {
        setCartIsLoading(true)
        const newCart = isFreshCart ? [] : [...cart]

        const currentCartID = await checkForCartID(isFreshCart)

        if (currentCartID) {
            const productInCart = newCart.find(
                item =>
                    item.product_strapi_id === product.strapi_id &&
                    item.product_variant === variant?.groesse?.Name
            )

            const successfullAddedProduct = await addProductToStrapiCart(
                currentCartID,
                product,
                variant,
                count
            )

            if (successfullAddedProduct) {
                const trackItem = {
                    id: product.strapi_id,
                    name: product.Name,
                    category: product.kategorie?.Name,
                    variant: variant?.groesse?.Name,
                    brand: product.marke?.Name,
                    price: variant.Preis_Angebot || variant.Preis,
                }

                TrackAddProductToCartEvent(trackItem, count)

                if (productInCart) {
                    productInCart.product_count += count
                } else {
                    let productVariantImage = product?.Vorschaubild?.url

                    // filter through product variants and find the one with the same size as the selected one
                    const productVariant = product?.Variante?.find(
                        item => item.groesse?.Name === variant?.groesse?.Name
                    )
                    // if there is a product variant with the same size as the selected one, set the image to the product variant image
                    if (productVariant) {
                        if (productVariant?.Bildergalerie?.length > 0) {
                            productVariantImage =
                                productVariant?.Bildergalerie[0]?.url
                        }
                    }

                    newCart.push({
                        product_type: 'product',
                        product_strapi_id: product.strapi_id,
                        product_name: product.Name,
                        product_slug: product.slug,
                        product_edition: product?.edition?.Name,
                        product_variant: variant?.groesse?.Name,
                        product_variant_strapi_id: variant?.groesse?.strapi_id,
                        product_variant_price:
                            variant.Preis_Angebot || variant.Preis,
                        product_variant_normal_price: variant.Preis,
                        product_variant_discount: 0,
                        product_count: count,
                        product_image: productVariantImage,
                    })
                }
                setCart(newCart)
                setIsSidebarOpen(true)
            }
        }

        setCartIsLoading(false)
    }
    const addFreeProductToCart = async (product, productVariant, count) => {
        setCartIsLoading(true)
        const newCart = [...cart]

        const currentCartID = await checkForCartID()

        if (currentCartID) {
            let productVariantImage =
                productVariant?.Bildergalerie?.length > 0
                    ? productVariant?.Bildergalerie[0]?.url
                    : product?.Vorschaubild?.url

            newCart.push({
                product_type: 'freeProduct',
                product_strapi_id: product.strapi_id,
                product_name: product.Name + ' - Geschenk',
                product_slug: product.slug,
                product_edition: product?.edition?.Name,
                product_variant: productVariant?.groesse?.Name,
                product_variant_strapi_id: productVariant?.groesse?.strapi_id,
                product_variant_price: 0,
                product_variant_normal_price: 0,
                product_variant_discount: 0,
                product_count: count,
                product_image: productVariantImage,
            })

            setCart(newCart)
            setIsSidebarOpen(true)
        }

        setCartIsLoading(false)
    }

    const addBundleToCart = async (
        bundle,
        variantOne,
        variantTwo,
        count,
        isFreshCart = false
    ) => {
        setCartIsLoading(true)
        const newCart = isFreshCart ? [] : [...cart]

        const currentCartID = await checkForCartID(isFreshCart)

        if (currentCartID) {
            const bundleInCart = newCart.find(
                item =>
                    item.product_strapi_id === bundle.strapi_id &&
                    item.product_variant_one === variantOne?.groesse?.Name &&
                    item.product_variant_two === variantTwo?.groesse?.Name
            )

            const successfullAddedBundle = await addBundleToStrapiCart(
                currentCartID,
                bundle,
                variantOne?.groesse?.Name,
                variantTwo?.groesse?.Name,
                count
            )

            if (successfullAddedBundle) {
                const trackItem = {
                    id: 'b_' + bundle.strapi_id,
                    name: bundle.Name,
                    category: bundle.kategorie?.Name,
                    variant:
                        variantOne?.groesse?.Name +
                        ' + ' +
                        variantTwo?.groesse?.Name,
                    brand: bundle.marke?.Name,
                    price: bundle.Preis,
                }

                TrackAddProductToCartEvent(trackItem, count)

                if (bundleInCart) {
                    bundleInCart.product_count += count
                } else {
                    newCart.push({
                        product_type: 'bundle',
                        product_strapi_id: bundle.strapi_id,
                        product_name: bundle.Name,
                        product_edition: bundle?.edition?.Name,
                        product_count: count,
                        product_one_strapi_id:
                            bundle?.Produkt[0]?.produkt.strapi_id,
                        product_two_strapi_id:
                            bundle?.Produkt[1]?.produkt.strapi_id,
                        product_one_name: bundle?.Produkt[0]?.produkt.Name,
                        product_two_name: bundle?.Produkt[1]?.produkt.Name,
                        product_one_edition:
                            bundle?.Produkt[0]?.produkt.edition.Name,
                        product_two_edition:
                            bundle?.Produkt[1]?.produkt.edition.Name,
                        product_one_anzahl: bundle?.Produkt[0]?.Anzahl,
                        product_two_anzahl: bundle?.Produkt[1]?.Anzahl,
                        product_variant_one: variantOne?.groesse?.Name,
                        product_variant_two: variantTwo?.groesse?.Name,
                        product_variant_price: bundle.Preis,
                        product_one_image:
                            bundle?.Produkt[0]?.produkt?.Vorschaubild?.url,
                        product_two_image:
                            bundle?.Produkt[1]?.produkt?.Vorschaubild?.url,
                    })
                }
                setCart(newCart)
                setIsSidebarOpen(true)
            }
        }

        setCartIsLoading(false)
    }

    const removeProductFromCart = async (variant, strapi_id) => {
        setCartIsLoading(true)

        const newCart = [...cart]

        const successfullRemovedProduct = await removeProductToStrapiCart(
            cartID,
            strapi_id,
            variant
        )

        if (successfullRemovedProduct) {
            const productInCart = newCart.find(
                item =>
                    item.product_strapi_id === strapi_id &&
                    item.product_variant === variant
            )
            if (productInCart) {
                const index = newCart.indexOf(productInCart)
                newCart.splice(index, 1)
            }
            setCart(newCart)
        }
        setCartIsLoading(false)
    }

    const removeFreeProductFromCart = async (variant, strapi_id) => {
        setCartIsLoading(true)

        const newCart = [...cart]

        const productInCart = newCart.find(
            item =>
                item.product_strapi_id === strapi_id &&
                item.product_variant === variant &&
                item.product_type === 'freeProduct'
        )
        if (productInCart) {
            const index = newCart.indexOf(productInCart)
            newCart.splice(index, 1)
        }
        setCart(newCart)

        setCartIsLoading(false)
    }

    const removeBundleFromCart = async (strapi_id, variantOne, variantTwo) => {
        setCartIsLoading(true)

        const newCart = [...cart]

        const successfullRemovedBundle = await removeBundleToStrapiCart(
            cartID,
            strapi_id,
            variantOne,
            variantTwo
        )

        if (successfullRemovedBundle) {
            const bundleInCart = newCart.find(
                item =>
                    item.product_strapi_id === strapi_id &&
                    item.product_variant_one === variantOne &&
                    item.product_variant_two === variantTwo
            )
            if (bundleInCart) {
                const index = newCart.indexOf(bundleInCart)
                newCart.splice(index, 1)
            }
            setCart(newCart)
        }

        setCartIsLoading(false)
    }

    const decreaseProdcutInCart = async (variant, strapi_id) => {
        setCartIsLoading(true)
        const newCart = [...cart]

        const successfullDecreasedProduct = await decreaseProductToStrapiCart(
            cartID,
            strapi_id,
            variant
        )

        if (successfullDecreasedProduct) {
            const productInCart = newCart.find(
                item =>
                    item.product_strapi_id === strapi_id &&
                    item.product_variant === variant
            )
            if (productInCart) {
                productInCart.product_count--
                if (productInCart.product_count === 0) {
                    const index = newCart.indexOf(productInCart)
                    newCart.splice(index, 1)
                }
            }
            setCart(newCart)
        }
        setCartIsLoading(false)
    }

    const decreaseBundleInCart = async (strapi_id, variantOne, variantTwo) => {
        setCartIsLoading(true)

        const newCart = [...cart]

        const successfullDecreasedBundle = await decreaseBundleToStrapiCart(
            cartID,
            strapi_id,
            variantOne,
            variantTwo
        )

        if (successfullDecreasedBundle) {
            const bundleInCart = newCart.find(
                item =>
                    item.product_strapi_id === strapi_id &&
                    item.product_variant_one === variantOne &&
                    item.product_variant_two === variantTwo
            )
            if (bundleInCart) {
                bundleInCart.product_count--
                if (bundleInCart.product_count === 0) {
                    const index = newCart.indexOf(bundleInCart)
                    newCart.splice(index, 1)
                }
            }
            setCart(newCart)
        }

        setCartIsLoading(false)
    }

    const increaseProdcutInCart = async (variant, strapi_id) => {
        setCartIsLoading(true)
        const newCart = [...cart]

        const successfullIncreasedProduct = await increaseProductToStrapiCart(
            cartID,
            strapi_id,
            variant
        )
        if (successfullIncreasedProduct) {
            const productInCart = newCart.find(
                item =>
                    item.product_strapi_id === strapi_id &&
                    item.product_variant === variant
            )
            if (productInCart) {
                productInCart.product_count++
            }
            setCart(newCart)
        }
        setCartIsLoading(false)
    }

    const increaseBundleInCart = async (strapi_id, variantOne, variantTwo) => {
        setCartIsLoading(true)

        const newCart = [...cart]

        const successfullIncreasedBundle = await increaseBundleToStrapiCart(
            cartID,
            strapi_id,
            variantOne,
            variantTwo
        )
        if (successfullIncreasedBundle) {
            const bundleInCart = newCart.find(
                item =>
                    item.product_strapi_id === strapi_id &&
                    item.product_variant_one === variantOne &&
                    item.product_variant_two === variantTwo
            )
            if (bundleInCart) {
                bundleInCart.product_count++
            }
            setCart(newCart)
        }
        setCartIsLoading(false)
    }

    const resetValues = ({ resetCapture = true }) => {
        if (resetCapture) {
            setCaptureCheckoutActions(null)
            setCaptureCheckoutOrderOrderId(null)
            setCaptureCheckoutOrderPayerId(null)
            setIsCaptureCheckout(false)
        }

        setCouponDiscount(null)
        setCouponDiscountVariant(null)
        setCouponDiscountID(null)
        setCouponName(null)
        setDeliveryDiscount(null)
        setPaymentOption('PayPal')
        setDiscount(null)
        setDeliveryMessage('')
        setDeliveryOption(null)
        setDiscountValue(0)
        setDiscountProducts([])
        setOhneAktionsprodukte(false)
    }

    const buyCartItems = async () => {
        // reset all
        resetValues({
            resetCapture: true,
        })
        setDeliveryForm({
            email: '',
            telephone: '',
            deliveryCountry: 'DE',
            deliveryFirstName: '',
            deliveryLastName: '',
            deliveryCompany: '',
            deliveryStreet: '',
            deliveryAdditionalInformation: '',
            deliveryZipCode: '',
            deliveryCity: '',
            otherBillingAddress: false,
            billingCountry: 'DE',
            billingFirstName: '',
            billingLastName: '',
            billingCompany: '',
            billingStreet: '',
            billingAdditionalInformation: '',
            billingZipCode: '',
            billingCity: '',
            acceptTerms: false,
            acceptDHLCheck: false,
        })

        // Reset cart
        setCart([])
        // Reset localstorage CartID
        if (isBrowser) {
            window.localStorage.removeItem('CartID')
        }

        //  Delete Strapi Cart
        if (cartID) {
            await deleteStrapiCart(cartID)
            setCartID(null)
        }

        // Check for inviting to newsletter
        const {
            email,
            telephone,
            billingFirstName,
            billingLastName,
            deliveryFirstName,
            deliveryLastName,
        } = deliveryForm

        let name = ''
        let surname = ''

        if (billingFirstName && billingLastName) {
            name = billingFirstName
            surname = billingLastName
        } else {
            name = deliveryFirstName || ''
            surname = deliveryLastName || ''
        }

        if (email) {
            addCustomerToNewsletter({
                language: language,
                email: email,
                name: name,
                surname: surname,
                telephone: telephone,
            })
        }
    }

    return (
        <AppContext.Provider
            value={{
                language,
                popUpIsOpen,
                checkoutPopUpIsOpen,
                showNavbar,
                isSidebarOpen,
                cart,
                cartIsLoading,
                cartProductCount,
                cartTotalPrice,
                isCaptureCheckout,
                captureCheckoutOrderPayerId,
                captureCheckoutOrderOrderId,
                captureCheckoutActions,
                deliveryForm,
                deliveryMessage,
                deliveryOption,
                couponDiscountValue,
                couponDiscountID,
                couponDiscountVariant,
                couponName,
                deliveryDiscountValue,
                discountValue,
                ohneAktionsprodukte,
                versandkostenValue,
                versandkostenfreiAb,
                gratisProduktAb,
                gratisProdukte,
                upSalesProdukte,
                paymentOption,
                setPopUpIsOpen,
                setCheckoutPopUpIsOpen,
                setShowNavbar,
                resetValues,
                closeSidebar,
                handleSidebar,
                buyCartItems,
                addProductToCart,
                addFreeProductToCart,
                addBundleToCart,
                removeProductFromCart,
                removeFreeProductFromCart,
                removeBundleFromCart,
                decreaseProdcutInCart,
                decreaseBundleInCart,
                increaseProdcutInCart,
                increaseBundleInCart,
                setIsCaptureCheckout,
                setCaptureCheckoutOrderPayerId,
                setCaptureCheckoutOrderOrderId,
                setCaptureCheckoutActions,
                setDeliveryForm,
                setDeliveryMessage,
                setDeliveryOption,
                setCouponDiscount,
                setCouponDiscountID,
                setCouponDiscountVariant,
                setCouponName,
                setOhneAktionsprodukte,
                setDiscountProducts,
                resetCart,
                setPaymentOption,
            }}
        >
            {children}
        </AppContext.Provider>
    )
}
